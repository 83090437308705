/**
 * BUTTONS
 *
 * Style for all types of buttons
 */

/* ------------------------------------ *\
   #STANDARD-BUTTON
\* ------------------------------------ */

.ras-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: $button-normal-height;
    padding: $spacing-unit;
    border: none;
    background-color: $color-button-bg;
    border-radius: 4px;
    color: #ffffff;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: 1;
    vertical-align: top;
    text-decoration: none;
    cursor: pointer;
    transition-property: border, background-color, color;
    transition-duration: $transition-durations;

    &:hover {
        background-color: $color-button-bg-hover;
        //border-color: $color-button-border-hover;
        color: #ffffff;
        text-decoration: none;
    }

    /* Disabled */
    &.disabled, &:disabled, &.disabled:hover, &:disabled:hover {
        color: $color-gray-01;
        //border-color: $color-button-border;
        background-color: lighten(desaturate($color-button-bg, 60%), 10%);
        cursor: not-allowed;
    }

    /* Active */
    &.active, &:active, &.active:hover, &:active:hover, &.is-active, &.is-active:hover {
        color: $color-button-text-hover;
        //border-color: $color-button-text-hover;
        background-color: lighten($color-button-bg, 5%);
    }

    [class*="fa"] {
        margin-right: $spacing-unit;
    }

}

/* ------------------------------------ *\
   #SECONDARY-BUTTON
\* ------------------------------------ */

.ras-btn-secondary {
    background: $color-button-secondary-bg;

    &:hover {
        background: $color-button-secondary-bg-hover;
    }

    &.disabled, &:disabled, &.disabled:hover, &:disabled:hover {
        background: $color-button-secondary-bg-disabled;
    }

    &.active, &:active, &.active:hover, &:active:hover, &.is-active, &.is-active:hover {
        background: $color-button-secondary-bg-active;
    }

}


/* ------------------------------------ *\
   #LIGHT-BUTTON
\* ------------------------------------ */
/* No background */

.ras-btn-light {
    padding: 0 4px;
    color: $color-button-light-text;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    text-decoration: none;
    //line-height: $button-normal-height;

    &:hover {
        color: $color-button-light-text-hover;
        background-color: transparent;
    }

    /* Disabled */
    &.disabled, &:disabled, &.disabled:hover, &:disabled:hover {
        color: $color-button-light-text-disabled;
        background-color: transparent;
        border: 0;
        cursor: not-allowed;
    }

    /* Active */
    &.active, &:active, &.active:hover, &:active:hover {
        color: lighten($color-main, 5%);
        background-color: transparent;
        border: 0;
    }
}

.ras-btn-view {
    padding-bottom: $spacing-unit*1.5;
    padding-left: 0;
    font-size: $font-size-ml;

    &:hover,
    &:focus,
    &:active {
        color: $color-text-hover;
        border-color: $color-text-hover;
    }

}

/* ------------------------------------ *\
   #CALL-TO-ACTION-BUTTON
\* ------------------------------------ */

.ras-btn-cta {
    //font-size: $font-size-s;
}

/* ------------------------------------ *\
   #BUTTON-SIZES
\* ------------------------------------ */

.ras-btn-l {
    height: $button-l-height-mobile;
    padding-right: $spacing-unit*2;
    padding-left: $spacing-unit*2;
    line-height: $button-l-height-mobile;
    font-size: $font-size-ml;
}


/* ------------------------------------ *\
   #BUTTON-ACCESSIBLE-LABEL
\* ------------------------------------ */

.ras-btn-accessible-label {
    @include accessible-text;
}


/* ------------------------------------ *\
   #READ-MORE
\* ------------------------------------ */

.ras-read-more {
    @include ellipsis-character;
    font-weight: $font-weight-bold;
    color: $color-text;
}


/* ------------------------------------ *\
   #READ-MORE
\* ------------------------------------ */

.ras-btn-icon {
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-s;

    [class*="fa"] {
        margin-right: $spacing-unit;
        font-size: $font-size-l;
    }

    &.ras-btn-undo {
        color: $color-text-light;

        &:hover,
        &:focus {
            color: $color-text-hover;
        }
    }
}


/* ------------------------------------ *\
   #ACTION
\* ------------------------------------ */

.ras-btn-action {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-xs;
    color: $color-text-light;
    position: relative;

    .ras-btn-action__number {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: $spacing-unit*1.5;
        height: $spacing-unit*1.5;
        border-radius: 50%;
        background: $color-text;
        position: absolute;
        top: $spacing-unit/2;
        right: 2px;
        color: #ffffff;
        font-size: 9px;
        font-weight: $font-weight-bold;
    }

    [class*="fa"] {
        color: $color-text;
    }

    .fa-filter {
        margin-right: $spacing-unit/2;
        font-size: $font-size-m;
    }

    [class*="fa-chevron"] {
        margin-left: $spacing-unit/2;
    }

    &:not(.ras-btn-action--has-label) {
        width: 45px;
        height: 45px;
        color: $color-text-light;

        [class*="fa"] {
            color: inherit;
            font-size: $font-size-ml;
        }
    }

    &:hover,
    &:focus {
        &:not(.ras-btn-action--has-label) {
            color: $color-text-hover;

        }
    }
}

/* Action wrapper */

.ras-action-wrapper {
    padding: $spacing-unit 0;
    text-align: center;
    //box-shadow: 0 0 5px rgba(0,0,0,0.2);
    a.ras-btn{
        margin-left: 15px;
    }
}

/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-btn {
        width: 100%;
        padding: $spacing-unit;
    }
    .ras-btn-action {
        &.ras-btn-action--has-label {
            .ras-btn-action__label-show,
            .fa-chevron-up {
                display: none;
            }
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-btn {
        min-width: $button-border-width;
    }

    .ras-btn-l {
    }


    .ras-btn-cta {
    }

    .ras-btn-action {
        font-size: $font-size-s;

        &.ras-mobile-visible {
            display: none;
        }

        .fa-filter {
            font-size: $font-size-ml;
        }

        &.ras-mobile-show {
            display: none;
        }

        &:not(.ras-btn-action--has-label) {
            [class*="fa"] {
                font-size: $font-size-l;
            }
        }

        &.ras-btn-action--has-label {
            .ras-btn-action__label-hide,
            .fa-chevron-down {
                display: none;
            }

            &.is-active {
                .ras-btn-action__label-hide,
                .fa-chevron-down {
                    display: inline-block;
                }

                .ras-btn-action__label-show,
                .fa-chevron-up {
                    display: none;
                }
            }
        }
    }

    .ras-action-wrapper {
        margin-top: auto;
    }

}